import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Collapsible from "react-collapsible"
import ReactPlayer from "react-player"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackBtn from "../components/backBtn"
import CollapsibleItem from "../components/collapsibleItemFaq"
import Image from "../components/tipImage"

const MoreContent = styled.div`
  margin: 3rem 0;
  ul {
    margin: 1rem 2rem;
  }
  .Collapsible__trigger {
    display: block;
    cursor: pointer;
    color: var(--main-black);
    font-size: 1.414rem;
    line-height: 1.6968rem;
    padding: 1rem 2rem;
    margin: 0;
    border-left: 2px solid var(--very-light-blue);
    svg {
      transition: transform 300ms;
    }
    &.is-open svg {
      transform: rotateZ(90deg);
    }
  }
  @media (max-width: 475px) {
    .Collapsible__trigger {
      font-size: 1.2rem;
    }
  }
`

const Tip = ({ data }) => {
  const {
    title,
    description,
    metaDescription,
    videoUrl,
    imageUrl,
    imageTag,
    url,
    tag,
  } = data.tips.edges[0].node
  const relatedContent = data.tipTag.edges.filter(
    entry => entry.node.url !== url
  )
  return (
    <Layout>
      <SEO title={title} description={metaDescription} />
      <div className="container">
        <h1>{title}</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/gameplay-tips/">Gameplay Tips</Link>
          </li>
          <li>{title}</li>
        </ul>
        {videoUrl && (
          <ReactPlayer
            url={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/gameplay-tips/${videoUrl}.mp4`}
            loop={true}
            playing={true}
            muted={true}
            playsinline={true}
            width="100%"
            height="100%"
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            style={{ marginBottom: "1rem" }}
          />
        )}
        {imageUrl && <Image fileName={imageUrl} alt={imageTag} />}
        {description.map((paragraph, index) => (
          <p dangerouslySetInnerHTML={{ __html: paragraph }} key={index} />
        ))}
        <MoreContent>
          {relatedContent.length >= 1 && (
            <Collapsible
              trigger={
                <CollapsibleItem text={`More tips related to: ${tag}`} />
              }
              contentContainerTagName="div"
            >
              <ul>
                {relatedContent.map(entry => (
                  <li key={entry.node.id}>
                    <Link to={`/gameplay-tips/${entry.node.url}/`}>
                      {entry.node.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Collapsible>
          )}
        </MoreContent>
        <BackBtn text="Back to Gameplay Tips" destination="/gameplay-tips/" />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query tips($url: String, $tag: String) {
    tips: allTip(filter: { url: { eq: $url } }) {
      edges {
        node {
          id
          url
          title
          tag
          imageUrl
          imageTag
          videoUrl
          description
          metaDescription
        }
      }
    }
    tipTag: allTip(filter: { tag: { eq: $tag } }) {
      edges {
        node {
          id
          url
          title
        }
      }
    }
  }
`

export default Tip
