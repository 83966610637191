import React from "react"
import styled from "styled-components"

const ImageBlock = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
  background: #b4b8c5;
  margin-bottom: 1rem;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`

const tipImage = ({ fileName, alt }) => {
  return (
    <ImageBlock>
      <img
        src={`https://f001.backblazeb2.com/file/pesmyclubguidemedia/gameplay-tips/${fileName}.jpg`}
        loading="lazy"
        alt={alt}
      />
    </ImageBlock>
  )
}

export default tipImage
