import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    padding-left: 1.5rem;
  }
`

const collapsibleItem = ({ text }) => {
  return (
    <Item>
      <blockquote>{text}</blockquote>
      <div className="icon">
        <FontAwesomeIcon icon="chevron-right" />
      </div>
    </Item>
  )
}

export default collapsibleItem
